
// 外部依赖
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
// api
import { getOrder, getVerifyDeliver } from '@/api/Allservices';
import { getSubject, getStage, getGrade } from '@/api/education';
import { getOrgInfoByOId } from '@/api/login';
// types
import { IOrderObject } from '@/types/orderDetail';
import { isArray } from '@/utils/validate';
import { IDeliver } from '@/types/service';
// 业务信息
import { IService, serviceList } from '@/business/ServiceContent';
// components
import needToKnow from './components/PlaceOrderInstructions.vue';
@Component({
  components: {
    needToKnow
  }
})
export default class Service extends Vue {
  @Action('setEduInfo') setEduinfo: any;
  layoutData: { name: string; list: IService[] }[] = [];
  v1Icon = require('@/assets/common/PPTIcon.svg');
  Visible = false;
  hasRead = false;
  tableLoading = false

  get isDebug () {
    const { query } = this.$route;
    return Object.hasOwnProperty.call(query, 'debug');
  }

  get orgId () {
    return this.$store.state.user?.orgs[0]?.orgId || 0;
  }

  async created () {
    let entries = [0, 2, 3, 5, 9];
    let OrgInfo;
    if (!this.$store.state.stage) {
      OrgInfo = await getOrgInfoByOId({ orgId: this.orgId });
      this.$store.state.OrgInfo = OrgInfo;
    } else {
      OrgInfo = this.$store.state.OrgInfo;
    }
    if (!this.$store.state.stage) this.$store.state.stage = await getStage({});
    if (!this.$store.state.subject) this.$store.state.subject = await getSubject({});
    if (OrgInfo.entries && OrgInfo.entries.length) entries = OrgInfo.entries.split(',').map((item: string) => Number(item));
    this.layoutData = serviceList.map(item => {
      return {
        name: item.name,
        list: item.list.filter(serve => entries.includes(serve.service_content))
      };
    }).filter(l => l.list.length);
    this.tableLoading = true;
    await this.getOwnOrder();
    await this.getEduInfo();
    this.tableLoading = false;
  }
  orderList = [];
  count = 0;
  async getEduInfo () {
    const storage = window.localStorage;
    try {
      const localSubject = JSON.parse(storage.getItem('subject') as string);
      const localStage = JSON.parse(storage.getItem('stage') as string);
      const localGrade = JSON.parse(storage.getItem('grade') as string);
      if (isArray(localSubject) && isArray(localStage) && isArray(localGrade) && localSubject.length && localStage.length && localGrade.length) {
        this.setEduinfo({
          subject: localSubject,
          grade: localGrade,
          stage: localStage
        });
        return;
      } else {
        throw new Error('get eduInfo');
      }
    } catch (error) {
      const [subject, stage, grade] = await Promise.all([getSubject({}), getStage({}), getGrade({})]);
      if (!storage.getItem('subject') || storage.getItem('subject') === 'null' || storage.getItem('subject') === 'undefined' || !storage.getItem('subject')?.length) {
        storage.setItem('subject', JSON.stringify(subject));
      }
      if (!storage.getItem('stage') || storage.getItem('stage') === 'null' || storage.getItem('stage') === 'undefined' || !storage.getItem('stage')?.length) {
        storage.setItem('stage', JSON.stringify(stage));
      }
      if (!storage.getItem('grade') || storage.getItem('grade') === 'null' || storage.getItem('grade') === 'undefined' || !storage.getItem('grade')?.length) {
        storage.setItem('grade', JSON.stringify(grade));
      }
      this.setEduinfo({
        subject,
        grade,
        stage
      });
    }
  }
  async getOwnOrder () {
    if (this.$store.state.user) {
      const { userId } = this.$store.state.user;
      const res = await getOrder({
        customer_id: userId
      });
      this.orderList = res.tickets;
      this.count = res.count;
    }
  }

  // 继续下单
  proceedOrder (row: IOrderObject) {
    const id = row.ticket_id;
    this.$router.push(`/create?id=${id}`);
  }
  // 订单详情
  detailVisible = false
  detailOrder: IOrderObject | null = null
  openDetail (row: IOrderObject) {
    this.detailOrder = row;
    this.detailVisible = true;
  }
  clearDetail () {
    this.detailOrder = null;
  }
  // 下载结果
  deliveryVisible = false
  deliverLoading = false
  deliverOrder: IOrderObject | null = null
  async openDelivery (row: IOrderObject) {
    this.deliverOrder = row;
    this.deliveryVisible = true;
    this.deliverLoading = true;
    await this.getDeliver(row.ticket_id);
    this.deliverLoading = false;
  }
  deliver: IDeliver | null = null
  async getDeliver (ticket_id: string) {
    try {
      const res = await getVerifyDeliver({
        ticket_id
      });
      (this.deliver as any) = res;
    } catch (error) {
      // this.$message.error('请求结果列表错误');
      // this.deliveryVisible = false;
      this.deliver = null;
    }
  }
  get OTHER () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.OTHER) && deliver.OTHER.length) {
      return deliver.OTHER;
    }
    return [];
  }
  clearDelivery () {
    this.deliverOrder = null;
    this.deliver = null;
  }
  // 复制到剪贴板
  copyToClipboard (text: string) {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      this.$message.success('复制成功');
    } catch (err) {
      this.$message.error('复制失败,该浏览器不支持点击复制到剪贴板');
    }
    document.body.removeChild(textArea);
  }
  // 直接下载
  download (row: { name: string; url: string }) {
    const a = document.createElement('a');
    a.href = row.url;
    a.click();
  }
  // 销毁前
  beforeDestroy () {
  }
  showNeedToKnow () {
    this.Visible = true;
  }
  checkHasRead () {
    if (this.hasRead === true) {
      this.$router.push('/create');
    } else {
      this.$message({
        showClose: true,
        message: '请勾选“我已阅读下单须知”',
        type: 'error'
      });
    }
  }
  handleServieClick (service: IService) {
    window.sessionStorage.setItem('service_type', service.mapKey);
    window.sessionStorage.setItem('service_name', service.name);
    this.$router.push({
      // tips: path 和 params 不能同时用
      // path: '/create',
      name: 'create',
      params: {
        serviceType: service.mapKey,
        name: service.name
      },
    });
  }
};
