
import { Vue, Component } from 'vue-property-decorator';
@Component({})
export default class extends Vue {
  hasRead = false;
  data = [{
    label: '一级', children: [{
      label: '二级'
    }]
  }];
  beforeCreate () {
  }
};
